
.accordion-title {
    display: flex;
    flex-direction: row;
    color: #ffffff;
    /* justify-content: space-between; */
    cursor: pointer;
    /* background-color: #43ca21; */
    border-radius: 5px;
    /* border: black 1px solid; */
  }
  
  
  .accordion-title,
  .accordion-content {

    padding: 1rem;
    /* border: black 1px solid; */
  }
  
  /* .accordion-content {
    transition-timing-function: ease-in;
    background-color: #ffffff;
  } */
  .accordion-item{
      margin-bottom: 0.5%;
  }

  .accordion-item-extra{
    margin-bottom: 1.5%;
}

  .example-enter {
    opacity: 0.01;
  }
  
  .example-enter.example-enter-active {
    opacity: 1;
    /* transition: opacity 500ms ease-in; */
  }
  
  .example-leave {
    opacity: 1;
  }
  
  .example-leave.example-leave-active {
    opacity: 0.01;
    /* transition: opacity 300ms ease-in; */
  }

  .text-overflow {
    overflow: hidden;
    text-overflow: none;
    
  }
  

  table.center-table {
    margin: 0 auto;
  }
