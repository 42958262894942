.CardStyle{
    display:flex;
    flex-direction:row;
    
    width:98%; 
    margin:1%;    
}

.cardName{
    width:50%
}
/* Extra small devices (phones; 600px and down) */
@media only screen and (max-width: 600px) {
    .CardStyle{
        flex-direction: column;
    }

    .cardName{
        width:100%
    }
    h1{
        font-size: 25px;
        }
    h2{
        font-size: 20px;
    }
    h3{
        font-size: 18px;
        }
    h4{
        font-size: 10px;
    }
    h5{
        font-size: 10px;
        }
    h6{
        font-size: 8px;
    }
    p{
        font-size: 10px;
    }

}

/* Small devices (portrait tablets and large phones; 600px and up) */
@media only screen and (min-width: 600px) {
    .CardStyle{
        flex-direction: column;
    }
    .cardName{
        width:100%
    }
    h1{
        font-size: 25px;
        }
    h2{
        font-size: 20px;
    }
    h3{
        font-size: 18px;
        }
    h4{
        font-size: 15px;
    }
    h5{
        font-size: 15px;
        }
    h6{
        font-size: 10px;
    }
    p{
        font-size: 10px;
    }

}

/* Medium devices (landscape tablets; 768px and up) */
@media only screen and (min-width: 768px) {
    .cardName{
        width:100%
    }
    h1{
        font-size: 25px;
        }
    h2{
        font-size: 20px;
    }
    h3{
        font-size: 18px;
        }
    h4{
        font-size: 15px;
    }
    h5{
        font-size: 15px;
        }
    h6{
        font-size: 10px;
    }
    p{
        font-size: 10px;
    }
}

/* Large devices (laptops/desktops; 992px and up) */
@media only screen and (min-width: 992px) {
    .cardName{
        width:50%
    }

    h1{
        font-size: 30px;
        }
    h2{
        font-size: 25px;
    }
    h3{
        font-size: 20px;
        }
    h4{
        font-size: 18px;
    }
    h5{
        font-size: 10px;
        }
    h6{
        font-size: 15px;
    }
    p{
        font-size: 10px;
    }
}

/* Extra large devices (large laptops and desktops; 1200px and up) */
@media only screen and (min-width: 1200px) {
    .cardName{
        width:50%
    }
    h1{
        font-size: 40px;
        }
    h2{
        font-size: 35px;
    }
    h3{
        font-size: 30px;
        }
    h4{
        font-size: 25px;
    }
    h5{
        font-size: 20px;
        }
    h6{
        font-size: 15px;
    }
    p{
        font-size: 10px;
    }

}

.p-datepicker-calendar th{
    text-align: center;
} 