.notification {
  position: fixed;
  top: 20px;;
  right: 20px;
  color: white;
  padding: 20px; /* Increase padding */
  border-radius: 8px; /* Increase border radius */
  font-size: 20px; /* Increase font size */
  width: 300px; /* Set a width */
  text-align: center; /* Center the text */
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.5); /* Add a shadow for more emphasis */
}

.info{
  background-color: blue;
}
.error{
  background-color: #f44336;
}
.notification .close-button {
  position: absolute;
  top: 0;
  right: 0;
  background: none;
  border: none;
  font-size: 16px;
  padding: 10px;
  cursor: pointer;
  display: none;
}

.notification p {
  margin: 0;
  padding: 0;
}
