/* Extra small devices (phones, 600px and down) */
@media only screen and(max-width: 600px) {
    .Dialog {
        width: 90%
    }
    h1 {
        font-size: 25px;
    }
    h2 {
        font-size: 20px;
    }
    h3 {
        font-size: 18px;
    }
    h4 {
        font-size: 12px;
    }
    h5 {
        font-size: 8px;
    }
    h6 {
        font-size: 8px;
    }
    p {
        font-size: 1rem;
    }

}

/* Small devices (portrait tablets and large phones, 600px and up) */
@media only screen and(min-width: 600px) {
    .Dialog {
        width: 50%
    }
    h1 {
        font-size: 25px;
    }
    h2 {
        font-size: 20px;
    }
    h3 {
        font-size: 18px;
    }
    h4 {
        font-size: 12px;
    }
    h5 {
        font-size: 8px;
    }
    h6 {
        font-size: 8px;
    }
    p {
        font-size: 1rem;
    }

}

/* Medium devices (landscape tablets, 768px and up) */
@media only screen and(min-width: 768px) {
    .Dialog {
        width: 50%
    }
    h1 {
        font-size: 25px;
    }
    h2 {
        font-size: 20px;
    }
    h3 {
        font-size: 18px;
    }
    h4 {
        font-size: 12px;
    }
    h5 {
        font-size: 8px;
    }
    h6 {
        font-size: 8px;
    }
    p {
        font-size: 1rem;
    }

}

/* Large devices (laptops/desktops, 992px and up) */
@media only screen and(min-width: 992px) {
    .Dialog {
        width: 25%
    }
    h1 {
        font-size: 30px;
    }
    h2 {
        font-size: 25px;
    }
    h3 {
        font-size: 20px;
    }
    h4 {
        font-size: 12px;
    }
    h5 {
        font-size: 8px;
    }
    h6 {
        font-size: 8px;
    }
    p {
        font-size: 1rem;
    }
}

/* Extra large devices (large laptops and desktops, 1200px and up) */
@media only screen and(min-width: 1200px) {
    .Dialog {
        width: 25%
    }
    h1 {
        font-size: 40px;
    }
    h2 {
        font-size: 35px;
    }
    h3 {
        font-size: 30px;
    }
    h4 {
        font-size: 12px;
    }
    h5 {
        font-size: 8px;
    }
    h6 {
        font-size: 8px;
    }
    p {
        font-size: 1rem;
    }

}


.p-button-text:focus {
    outline: 0;
}

.order_buttons {
    color: white !important;
    border-color: #00a352 !important;
    margin-right: 1%;
    background-color: #00a352 !important;
    border-color: #00a352 !important;
}

.white_order_button {
    color: #00a352;
    border-color: #00a352 !important;
    margin-right: 1%;
    background-color: white;
}
.alternate_color {
    color: white !important;
    border-color: #fc9803 !important;
    margin-right: 1%;
    background-color: #fc9803 !important;
    border-color: #fc9803 !important;
}

.consolidated_header_color {
    color: #00a352;
    border-color: rgba(0, 0, 0, 0);
}

.consolidated_order_buttons {
    color: #00a352 !important;
    border-color: #00a352 !important;
    margin-right: 1%;
    width: 50%;
}

.consolidated_cancel_button {
    color: #873e23 !important;
    border-color: #873e23 !important;
}

tr.noBorder td {
    border: 0;
}

tr.showBorder {
    width: 50%;
    border-top: 1px solid;
    border-bottom: 1px solid;
}

.my-node-enter {
    opacity: 0;
}
.my-node-enter-active {
    opacity: 1;
    transition: opacity 500ms;
}
.my-node-exit {
    opacity: 1;
}
.my-node-exit-active {
    opacity: 0;
    transition: opacity 500ms;
}

.p {
    font-size: 1rem !important;
}

.archiving_navigation_buttons:hover {
    background-color: transparent !important;
    color: #00a352 !important;
}

