.CheckBox{
    width: 10vw;
    /* margin-left: 15px; */

    text-align: center;
    /* border: 1px black solid; */
    top: 0;
    /* left: 15px; */
}
.p-checkbox-label{
    width: auto;
    text-align: center;

}
.col-12{
    /* top:0; */
    display: flex;
    flex-direction: row;
}


.p-checkbox {
    width: 20px;
    height: 20px;
  }
  .p-checkbox .p-checkbox-box {
    border: 2px solid #ced4da;
    background: #ffffff;
    width: 20px;
    height: 20px;
    color: #495057;
    border-radius: 3px;
    transition: background-color 0.2s, color 0.2s, border-color 0.2s, box-shadow 0.2s;
  }
  .p-checkbox .p-checkbox-box .p-checkbox-icon {
    transition-duration: 0.2s;
    color: #ffffff;
    font-size: 14px;
  }
  .p-checkbox .p-checkbox-box.p-highlight {
    border-color: #04b7eb;
    background: #04b7eb;
  }
  .p-checkbox .p-checkbox-box.p-highlight:not(.p-disabled):hover {
    border-color: #04b7eb;
    background: #04b7eb;
    color: #ffffff;
  }
  .p-checkbox:not(.p-checkbox-disabled) .p-checkbox-box:hover {
    border-color: #04b7eb;
  }
  .p-checkbox:not(.p-checkbox-disabled) .p-checkbox-box.p-focus {
    outline: 0 none;
    outline-offset: 0;
    box-shadow: 0 0 0 0.2rem #b7e0b8;
    border-color: #04b7eb;
  }
  .p-checkbox:not(.p-checkbox-disabled) .p-checkbox-box.p-highlight:hover {
    border-color: #04b7eb;
    background: #04b7eb;
    color: #ffffff;
  }
  .p-checkbox.p-invalid > .p-checkbox-box {
    border-color: #f44336;
  }
  
  .p-input-filled .p-checkbox .p-checkbox-box {
    background-color: #f8f9fa;
  }
  .p-input-filled .p-checkbox .p-checkbox-box.p-highlight {
    background: #04b7eb;
  }
  .p-input-filled .p-checkbox:not(.p-checkbox-disabled) .p-checkbox-box:hover {
    background-color: #f8f9fa;
  }
  .p-input-filled .p-checkbox:not(.p-checkbox-disabled) .p-checkbox-box.p-highlight:hover {
    background: #04b7eb;
  }
