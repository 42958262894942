/* Extra small devices (phones, 600px and down) */
@media only screen and (max-width: 600px) {
    h1{
        font-size: 25px;
        }
    h2{
        font-size: 20px;
    }
    h3{
        font-size: 18px;
        }
    h4{
        font-size: 13px;
    }
    h5{
        font-size: 8px;
        }
    h6{
        font-size: 15px;
    }
    p{
        font-size: 1rem;
    }

}

/* Small devices (portrait tablets and large phones, 600px and up) */
@media only screen and (min-width: 600px) {
    h1{
        font-size: 25px;
        }
    h2{
        font-size: 20px;
    }
    h3{
        font-size: 18px;
        }
    h4{
        font-size: 13px;
    }
    h5{
        font-size: 8px;
        }
    h6{
        font-size: 8px;
    }
    p{
        font-size: 1rem;
    }

}

/* Medium devices (landscape tablets, 768px and up) */
@media only screen and (min-width: 768px) {
    h1{
        font-size: 25px;
        }
    h2{
        font-size: 20px;
    }
    h3{
        font-size: 18px;
        }
    h4{
        font-size: 13px;
    }
    h5{
        font-size: 8px;
        }
    h6{
        font-size: 15px;
    }
    p{
        font-size: 1rem;
    }
}

/* Large devices (laptops/desktops, 992px and up) */
@media only screen and (min-width: 992px) {
    h1{
        font-size: 30px;
        }
    h2{
        font-size: 25px;
    }
    h3{
        font-size: 20px;
        }
    h4{
        font-size: 18px;
    }
    h5{
        font-size: 10px;
        }
    h6{
        font-size: 15px;
    }
    p{
        font-size: 1rem;
    }
}

/* Extra large devices (large laptops and desktops, 1200px and up) */
@media only screen and (min-width: 1200px) {
    h1{
        font-size: 40px;
        }
    h2{
        font-size: 35px;
    }
    h3{
        font-size: 30px;
        }
    h4{
        font-size: 25px;
    }
    h5{
        font-size: 20px;
        }
    h6{
        font-size: 15px;
    }
    p{
        font-size: 1rem;
    }

}

.p-button-text:focus{
    outline:0;
  }

.order_buttons {
    color: white !important;
    border-color: #00a352 !important;
    margin-right: 1%;
    background-color: #00a352 !important;
    border-color: #00a352 !important;
}  

.dasboard_header_labels{
    margin-top: 5%;
}

.dashboard_header_input{
    margin-top: 0%;
    margin-bottom: 5%;
    height: 70%;
    width: 70%;
}