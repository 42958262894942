
.p-inputnumber {
    text-align: center;
}
/* Extra small devices (phones; 600px and down) */
@media only screen and (max-width: 600px) {
    .packagingLabels{
        width:30%;   
        display:flex; 
        flex-direction:column;
        justify-content:flex-start; 
        align-items:center;
    }
    h1{
        font-size: 25px;
        }
    h2{
        font-size: 20px;
    }
    h3{
        font-size: 18px;
        }
    h4{
        font-size: 15px;
    }
    h5{
        font-size: 15px;
        }
    h6{
        font-size: 15px;
    }
    p{
        font-size: 15px;
    }

}

/* Small devices (portrait tablets and large phones; 600px and up) */
@media only screen and (min-width: 600px) {
    .packagingLabels{
        width:30%;   
        display:flex; 
        flex-direction:column;
        justify-content:flex-start; 
        align-items:center;
    }
    h1{
        font-size: 25px;
        }
    h2{
        font-size: 20px;
    }
    h3{
        font-size: 18px;
        }
    h4{
        font-size: 15px;
    }
    h5{
        font-size: 15px;
        }
    h6{
        font-size: 15px;
    }
    p{
        font-size: 15px;
    }

}

/* Medium devices (landscape tablets; 768px and up) */
@media only screen and (min-width: 768px) {
    .packagingLabels{
        width:30%;   
        display:flex; 
        flex-direction:column;
        justify-content:flex-start; 
        align-items:center;
    }
    h1{
        font-size: 25px;
        }
    h2{
        font-size: 20px;
    }
    h3{
        font-size: 18px;
        }
    h4{
        font-size: 13px;
    }
    h5{
        font-size: 13px;
        }
    h6{
        font-size: 13px;
    }
    p{
        font-size: 13px;
    }
}

/* Large devices (laptops/desktops; 992px and up) */
@media only screen and (min-width: 992px) {
    .packagingLabels{
        width:30%;   
        display:flex; 
        flex-direction:column;
        justify-content:flex-start; 
        align-items:center;
    }
    h1{
        font-size: 30px;
        }
    h2{
        font-size: 25px;
    }
    h3{
        font-size: 20px;
        }
    h4{
        font-size: 13px;
    }
    h5{
        font-size: 13px;
        }
    h6{
        font-size: 13px;
    }
    p{
        font-size: 13px;
    }
}

/* Extra large devices (large laptops and desktops; 1200px and up) */
@media only screen and (min-width: 1200px) {

    .packagingLabels{
        width:40%;   
        display:flex; 
        flex-direction:row;
        justify-content:flex-start; 
        align-items:center;
    }
    h1{
        font-size: 40px;
        }
    h2{
        font-size: 35px;
    }
    h3{
        font-size: 30px;
        }
    h4{
        font-size: 20px;
    }
    h5{
        font-size: 20px;
        }
    h6{
        font-size: 15px;
    }
    p{
        font-size: 20px;
    }
}

.difference_in_volume_color {
    color:#fc9803 !important;
}