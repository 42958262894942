.Dropdown-root {

    width: 100%;
    border: red 1px solid;
    height: 100%;
  }
  
  .Dropdown-control {

    overflow: hidden;
    height: 100%;

    border-radius: 2px;
    box-sizing: border-box;
    color: rgb(255, 0, 0);
    cursor: default;

    padding: 3%;


  }
  
  .Dropdown-control:hover {
    box-shadow: 0 1px 0 rgba(0, 0, 0, 0.06);
  }
  
  .Dropdown-arrow {
    border-color: rgb(224, 0, 0);
    border-style: solid;
    border-width: 5px 5px 0;
    display: block;

    position: relative;
    right: 5%;
    top: 14px;
    width: 0
  }
  
  .is-open .Dropdown-arrow {
    border-color:  rgb(255, 255, 255);
    border-width: 0 5px 5px;
    height: 100%;
  }
  
  .Dropdown-menu {
    background-color: #ffffff;
    border: 1px solid #ff0000;
    box-shadow: 0 1px 0 rgba(0, 0, 0, 0.06);
    align-items: center;
    justify-content: center;
    margin-top: -1px;
    /* max-height: 200px; */
    overflow-y: auto;
    position: relative;
    /* top: 100%; */
    width: 100%;
    z-index: 1000;
    -webkit-overflow-scrolling: touch;
  }
  
  .Dropdown-menu .Dropdown-group > .Dropdown-title{
    padding: 8px 10px;
    color: rgba(51, 51, 51, 1);
    font-weight: bold;
    text-transform: capitalize;
  }
  
  .Dropdown-option {
    box-sizing: border-box;
    color: rgba(51, 51, 51, 0.8);
    cursor: pointer;
    display: block;
    padding: 8px 10px;
  }
  
  .Dropdown-option:last-child {
    border-bottom-right-radius: 2px;
     border-bottom-left-radius: 2px;
  }
  
  .Dropdown-option:hover {
    background-color: #ffffff;
    color: #333;
  }
  
  .Dropdown-option.is-selected {
    background-color: #ffffff;
    color: #333;
  }
  
  .Dropdown-noresults {
    box-sizing: border-box;
    color: rgb(255, 255, 255);
    cursor: default;
    display: block;
    padding: 8px 10px;
  }
  