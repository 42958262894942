.imgStyle {
    /* width:250px; */
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.small-info {
    font-size: 0.8rem;
    line-height: 1rem;
    font-weight: 1rem;
    color: #0077b6;
    margin-top: 1rem;
  }
