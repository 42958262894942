.my-node-enter {
    opacity: 0;
  }
  .my-node-enter-active {
    opacity: 1;
    transition: opacity 500ms;
  }
  .my-node-exit {
    opacity: 1;
  }
  .my-node-exit-active {
    opacity: 0;
    transition: opacity 500ms;
  }

.p-button-text:focus{
  outline:0;
}

.top-buffer { 
  padding-top:1rem; 
}

.standard_button {
  color: #00a352 !important;
  border-color: #00a352 !important;
}

.blue {
  color: #6366F1;
  border-color: #6366F1;
}

.cancelled_color{
  color:gray !important;
  background-color:  transparent;
}

.declined_color {
  color:#fc9803;
  background-color:  transparent;
}

.approved_color {
  color:#00a352;
  background-color:  transparent;
}
