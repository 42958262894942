.order_buttons {
    color: white;
    border-color: #00a352;
    margin-right: 1%;
    background-color: #00a352;
    border-color: #00a352;
}

.order_buttons_in_wait_status {
    color: white !important;
    border-color:#6366F1 !important;
    margin-right: 1% !important;
    background-color: #6366F1 !important;
    border-color:#6366F1 !important;
}

.order_buttons_in_rejected_status {
    color: white !important;
    border-color: #873e23 !important;
    margin-right: 1% !important;
    background-color: #873e23 !important;
    border-color: #873e23 !important;
}

.order_buttons_in_cancelled_status{
    color: white;
    border-color: gray !important;
    margin-right: 1% !important;
    background-color:  gray !important;
    border-color:  gray !important;
}

.p {
    font-size: 1rem !important;
}

tr.noBorder td {
    border: 0;
  }

tr.showBorder {
    width: 50%;
    border-top: 1px solid; 
    border-bottom: 1px solid;
}
