.pastOrderCard{
    width:90%; 
    height:100%; 
    display:flex; 
    flex-direction:row; 
    margin-bottom:1%;
    margin-top:10%;
    border-color:#00a352; 
    border:2px solid #00a352; 
    border-radius:0px 25px 0px 0px;
}
.topButtonDiv{
    width:30%;
    margin-right:0%; 
    height:100%;
    display:flex; 
    flex-direction:row;  
    align-items:center; 
    justify-content:flex-end;
}
.infoTopCard{
    width:70%;
}

/* Extra small devices (phones; 600px and down) */
@media only screen and (max-width: 600px) {
    .infoTopCard{
        width:90%;
    }
    .topButtonDiv{
        width:100%;
        justify-content:center;
    }
    .pastOrderCard{
        flex-direction:column; 
    }
    .ButtonDiv{
        width:100%;
        margin-right:5%; 
        height:100%;
        display:flex; 
        flex-direction:row;  
        align-items:center; 
        justify-content:center;
        /* border: 1px solid black; */
    }
    h1{
        font-size: 25px;
        }
    h2{
        font-size: 20px;
    }
    h3{
        font-size: 18px;
        }
    h4{
        font-size: 10px;
    }
    h5{
        font-size: 10px;
        }
    h6{
        font-size: 8px;
    }
    p{
        font-size: 10px;
    }

}

/* Small devices (portrait tablets and large phones; 600px and up) */
@media only screen and (min-width: 600px) {
    .ButtonDiv{
        width:50%;
        margin-right:0%; 
        height:100%;
        display:flex; 
        flex-direction:column;  
        align-items:center; 
        justify-content:flex-end;
     
    }
    h1{
        font-size: 25px;
        }
    h2{
        font-size: 20px;
    }
    h3{
        font-size: 18px;
        }
    h4{
        font-size: 15px;
    }
    h5{
        font-size: 15px;
        }
    h6{
        font-size: 10px;
    }
    p{
        font-size: 10px;
    }

}

/* Medium devices (landscape tablets; 768px and up) */
@media only screen and (min-width: 768px) {
    .ButtonDiv{
        width:50%;
        margin-right:0%; 
        height:90%;
        display:flex; 
        flex-direction:column;  
        align-items:center; 
        justify-content:flex-end;
       
    }
    h1{
        font-size: 25px;
        }
    h2{
        font-size: 20px;
    }
    h3{
        font-size: 18px;
        }
    h4{
        font-size: 15px;
    }
    h5{
        font-size: 15px;
        }
    h6{
        font-size: 10px;
    }
    p{
        font-size: 0.65rem;
    }
}

/* Large devices (laptops/desktops; 992px and up) */
@media only screen and (min-width: 992px) {
    .ButtonDiv{
        width:100%;
        margin-right:0%; 
        height:90%;
        display:flex; 
        flex-direction:row;  
        align-items:center; 
        justify-content:flex-end;
       
    }

    h1{
        font-size: 30px;
        }
    h2{
        font-size: 25px;
    }
    h3{
        font-size: 20px;
        }
    h4{
        font-size: 18px;
    }
    h5{
        font-size: 10px;
        }
    h6{
        font-size: 15px;
    }
    p{
        font-size: 10px;
    }
}

/* Extra large devices (large laptops and desktops; 1200px and up) */
@media only screen and (min-width: 1200px) {
    .ButtonDiv{
        width:100%;
        margin-right:0%; 
        height:100%;
        display:flex; 
        flex-direction:row;  
        align-items:center; 
        justify-content:flex-end;
       /* border: 1px solid black; */
    }
    h1{
        font-size: 40px;
        }
    h2{
        font-size: 35px;
    }
    h3{
        font-size: 30px;
        }
    h4{
        font-size: 20px;
    }
    h5{
        font-size: 20px;
        }
    h6{
        font-size: 15px;
    }
    p{
        font-size: 10px;
    }

}
