.SummaryRow{
  background-color: blue;
  color:green;
  width: 10vw;
  /* margin-left: 15px; */

  text-align: center;
  /* border: 1px black solid; */
  top: 0;
  /* left: 15px; */
}
